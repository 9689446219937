<template>
  <div class="details">
      <div class="details-wrapper">
        <div class="zlxc-container" style="overflow: visible">
          <div class="bread-crumbs">
            <el-breadcrumb separator="/">
              <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item>{{detailType == 'exposure' ? '曝光台' : detailType == 'about' ? '行业动态' : detailType == 'knowledge' ? '学驾须知' : '政策法规'}}</el-breadcrumb-item>
              <el-breadcrumb-item>详情</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="article" v-if="articleObj">
            <p class="article-title">{{articleObj.title}}</p>
            <ul class="article-feature">
              <li class="feature-writing">
                <p class="writing-branch">发布时间：{{articleObj.createDate}}</p>
                <!-- <p class="writing-branch">发布部门：{{articleObj.source ? articleObj.source : '洛阳市洛阳学车'}}</p> -->
              </li>
              <li class="feature-operate">
                <p class="operate-name">字体：</p>
                <p class="operate-item" @click="tapFontSize('big')">大</p>
                <p class="operate-item" @click="tapFontSize('middle')">中</p>
                <p class="operate-item" @click="tapFontSize('small')">小</p>
              </li>
            </ul>
            <!-- <div class="view-attach">
              <strong>点击查看附件：</strong><span>《{{articleObj.title}}》</span>
            </div> -->
            <ul :class="['article-body', {'small':fontSize == 'small'}, {'middle':fontSize == 'middle'},  {'big':fontSize == 'big'}]">
              <li class="article-part" v-html="articleObj.mainBody"></li>
              <!-- <li class="article-part">
                机动车驾驶培训教学与考试大纲包括：机动车驾驶培训教学大纲、机动车驾驶人考试大纲和驾驶培训教学日志。
              </li> -->
              <!-- <li class="gap"></li>
              <li class="article-coda">中文名</li> -->
            </ul>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { queryIndustryDynamicDetails, queryGetLawDetails, queryEssayDetail ,getPublicNoticeByDetails} from '@/api/herbalMedicine'
import { formatTime } from '@/utils/common.js'

export default {
  name: 'Details',
  data () {
    return {
      // 字体大小：默认 middle中
      fontSize: 'middle',
      // 文章详情ID值
      detailId: null,
      // 文章详情类型值，默认曝光台
      detailType: 'exposure',
      // 文章详情
      articleObj: null
    }
  },
  created () {
    this.detailId = this.$route.query.id
    this.detailType = this.$route.query.type
    if (this.$route.query.type === 'legislation') {
      // 政策法规详情内容
      this.getQueryGetLawDetails()
    } else if (this.$route.query.type === 'knowledge') {
      // 学车须知或者驾驶常识详情接口
      this.getQueryEssayDetail()
    } else if (this.$route.query.type === 'notice') {
      // 公示栏详情接口
      this.getPublicNoticeByDetails()
    } else {
      // 行业动态/曝光台详情内容
      this.getQueryIndustryDynamicDetails()
    }
  },
  methods: {
    // 改变字体大小
    tapFontSize (size) {
      this.fontSize = size;
    },
    //公示栏
    getPublicNoticeByDetails(){
           getPublicNoticeByDetails('publicNoticeId='+this.detailId).then(res => {
             debugger
              res.data.createDate = formatTime(res.data.createDate)
              this.articleObj = res.data
            })
    },
    // 学车须知或者驾驶常识详情接口
    getQueryEssayDetail () {
      queryEssayDetail({
        essayId  : this.detailId
      }).then(res => {
        res.data.createDate = formatTime(res.data.createTime)
        this.articleObj = res.data
      })
    },
    // 行业动态/曝光台详情内容
    getQueryIndustryDynamicDetails () {
      queryIndustryDynamicDetails({
        id : this.detailId
      }).then(res => {
        res.data.createDate = formatTime(res.data.createTime)
        this.articleObj = res.data
        console.log(this.articleObj)
      })
    },
    // 政策法规详情内容
    getQueryGetLawDetails () {
      queryGetLawDetails({
        lawId : this.detailId
      }).then(res => {
        res.data.createDate = formatTime(res.data.createDate)
        this.articleObj = res.data
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";

</style>

<style scoped>
.article-part >>> img {
  max-width: 100% !important;
}
</style>